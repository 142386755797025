import React from 'react';
import { IonButton, IonModal, IonContent, IonHeader, IonToolbar, IonButtons, IonTitle, IonIcon } from '@ionic/react';
import store from '../../../store';
import LimeAPI from '../../../classes/LimeAPI';
import isBrowser from '../../../helpers/is-browser';
import { currentUserIsAdmin } from '../../../helpers/roles';
import { loadingController, toastController } from '@ionic/core';
import { withRouter } from 'react-router-dom';
import { storeQualityDocument, storeCurrentQualityDocument, verifyQualityDocumentOnApproval } from '../../../helpers/quality-document';
import { getNewProjectStatus, setProjectStatus } from '../../../helpers/project';
import SignModal from '../../BankId/SignModal';
import { closeCircle } from 'ionicons/icons';
import { Device } from '@capacitor/device';
import { App } from '@capacitor/app';

interface State {
  isLoading: boolean;
  bankIdMessage: any;
  retries: any;
  qrImageUpdated?: string;
  sameDevice: boolean;
  qrImage: string;
}
class UpdateStatusButton extends React.Component<any, any> {
  abortController = new AbortController();
  _isMounted: boolean;

  constructor(props: any) {
    super(props);

    this.state = {
      isSubmitting: false,
      showModalApprove: false,
      showModalBankId: false,
      fields: [],
      iframeUrl: '',
      signProcessActive: false,
      bankIdSigned: false,
      bankIdMessage: '',
      qrImage: '',
      orderRef: '',
      qrImageUpdated: '',
      retries: 0, 
    };

    this.updateStatusButtonClick = this.updateStatusButtonClick.bind(this);
    this.updateModalButtonClick = this.updateModalButtonClick.bind(this);

    this.updateStatus = this.updateStatus.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
    
    // Listen for the app coming back to the foreground
    App.addListener('appStateChange', (state) => {
      if (state.isActive && this.state.signProcessActive && this.state.orderRef) {
        this.checkSignStatus(this.state.orderRef, store.getState().currentQualityDocument._id);
      }
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.abortController.abort();

      // Clean up listeners
    App.removeAllListeners();
  }

  async updateQualityDocument() {
    const state = store.getState();
    const { currentQualityDocument } = state;
    const { fields } = this.state;
    const fieldsObj: any = {};
    fields.forEach((field: any) => fieldsObj[field] = true);

    const data = {
      qualitydocumentstatus: this.props.updateValue,
      company: currentQualityDocument.company._id,
      _id: currentQualityDocument._id,
      ...fieldsObj,
    };

    data.picture = [ ...currentQualityDocument.picture.map((picture: any) => {
      return 'data:image/' + picture.extension + ';base64,' + picture.data;
    })];

    return LimeAPI.put(`qualitydocuments/${currentQualityDocument._id}`, data, this.abortController.signal);
  }

  /* Slutgodkänn kvalitetsdokumentet knapp */
  async updateStatusButtonClick() {
    const fields = verifyQualityDocumentOnApproval();
    const { updateValue } = this.props;

    if (fields.length !== 0 && currentUserIsAdmin() && updateValue === 'approved') {
      this.setState({ showModalApprove: true, fields });
      return;
    }

    await this.submitQualityDocument();
    return;
  }

  async submitQualityDocument() {

    if (this.state.isSubmitting) {
      return;
    }

    this.setState({ isSubmitting: true });
    if (this.props.updateValue === 'approved') {
      this.setState((prevState: State) => ({
        ...prevState,
        showModalBankId: true,
        isLoading: true,
        bankIdMessage: 'Initierar BankID...',
      }));

      /* Här fastnar vi */
      this.signBankID().catch(() => false);

      return;
    }

    this.updateStatus()
      .catch(() => false);
  }

  async signBankID() {
    if (this.state.signProcessActive) {
      return;
    }
    const state = store.getState();
    const { user, currentQualityDocument } = state;
    const sameDeviceStored = localStorage.getItem('sameDevice');
    const sameDevice = sameDeviceStored !== null ? JSON.parse(sameDeviceStored) : true;
    const info = await Device.getInfo();

    return LimeAPI.post('signBankID',{
      pin: user.identityNoLong,
      documentId: currentQualityDocument._id,
      sameDevice,
      platform: info.platform,
    }, this.abortController.signal)
      .then(data => {
        if (! data.success) {
          this.setState({ errorMsg: data.message, isLoading: false });
          return;
        }

        const image_url = data.data.qr_code_link;
        if (!data.data.same_device && image_url) {
          this.setState({qrImage: image_url, qrImageUpdated: `${image_url}&t=${new Date().getTime()}` });
        } else {
          if (!isBrowser()) {
            window.location.href = data.data.redirect_url;
          } else {
            window.open(data.data.redirect_url, '_blank');
          }
        }

        this.setState({ signProcessActive: true, orderRef: data.data.orderRef });
        setTimeout(() => this.checkSignStatus(data.data.orderRef, currentQualityDocument._id), 2000);
      })
      .catch(() => {
        this.setState({
          errorMsg: 'Något gick fel, försök igen.',
          isLoading: false,
        });
      });
  }

  handleQRImageTimeout = () => {
    this.signBankID().catch(() => false);
  }

  checkSignStatus(orderRef: any, qualityDocumentId: any) {
    const sameDeviceStored = localStorage.getItem('sameDevice');
    const sameDevice = sameDeviceStored !== null ? JSON.parse(sameDeviceStored) : true;
    LimeAPI.post(`signBankID/checkSignStatus`, {
      orderRef,
      qualityDocumentId,
      sameDevice,
    }, this.abortController.signal)
    .then(data => {
      this.setState({ bankIdMessage: data.data.message });
      if (! data.success) {
        this.setState({
          signProcessActive: false,
          isLoading: false,
          retries: 0,
        });
        this.closeModal();
        return;
      }

      if (data.data.retry) {
        const newRetries = this.state.retries + 1;
        if (newRetries < 13) {
          const newState: Partial<State> = {
            isLoading: false,
            bankIdMessage: data.data.message,
            retries: newRetries,
          };

          if (sameDevice && this.state.qrImage) {
            newState.qrImageUpdated = `${this.state.qrImage}&t=${new Date().getTime()}`;
          }
          this.setState(newState);
          
          if (sameDevice) {
            setTimeout(() => {
              this.setState((prevState: State) => ({
                qrImageUpdated: `${prevState.qrImage}&t=${new Date().getTime()}`,
              }));
            }, 1000);
          }

          setTimeout(() => this.checkSignStatus(orderRef, qualityDocumentId), 2000);
        } else {
          this.setState({
            signProcessActive: false,
            isLoading: false,
            retries: 0,
            bankIdMessage: data.data.message,
          });
          this.closeModal();
        }
      }

      if (data.data.status === 'COMPLETE') {
        setTimeout(() => {
          this.closeModal();
      
          if (!this.state.hasUpdatedStatus) {
            this.setState({
              hasUpdatedStatus: true,
              signProcessActive: false,
              isLoading: false,
              retries: 0,
            });
      
            this.updateStatus().catch((error) => {
              console.warn('Error in updateStatus:', error);
              this.setState({ hasUpdatedStatus: false });
            });
          } else {
            console.warn('updateStatus already called, skipping...');
          }
        }, 2000);
      }
      
    })
    .catch((error) => {
      if (this._isMounted) {
        console.warn('Error during checkSignStatus:', error);
        this.setState({
          errorMsg: 'Något gick fel, försök igen.',
          isLoading: false,
        });
      }
    });
  }

  async updateStatus() {
    const state = store.getState();
    const { currentQualityDocument } = state;

    const controller = await loadingController.create({
      spinner: 'crescent',
      message: 'Uppdaterar status...',
    });

    await controller.present();

    const response = await this.updateQualityDocument();

    loadingController.dismiss().catch();

    if (! response.success) {
      const toast = await toastController.create({
        message: 'Statusen för kvalitetsdokumentet kunde inte uppdateras, försök igen.',
        color: 'dark',
        buttons: ['Ok'],
      });

      await toast.present();

      return;
    }

    storeQualityDocument({
      ...response.data,
    });

    if (this.props.updateValue === 'approved') {
      currentQualityDocument.qualitydocumentstatus = 'approved';
      currentQualityDocument.supplementtoclient = response.data.supplementtoclient;
      currentQualityDocument.copytoauthorizedcompany = response.data.copytoauthorizedcompany;
      currentQualityDocument.signdate = response.data.signdate;
      currentQualityDocument.signaturename = response.data.signaturename;

      const data = {
        qualitydocument: currentQualityDocument,
      };
      storeCurrentQualityDocument(data);
    }

    const projectStatuses = getNewProjectStatus();

    await setProjectStatus(projectStatuses['projectstatus'], projectStatuses['status_tiler']);

    if (this._isMounted) {
      this.setState({ isSubmitting: false });
    }

    if (this.props.updateValue === 'approved') {
      return this.props.openActionSheet();
    } else {
      const toastSuccess = await toastController.create({
        message: 'Statusen för kvalitetsdokumentet har nu uppdaterats.',
        duration: 3000,
        color: 'success',
      });

      await toastSuccess.present();

      return this.props.history.push(`/projects/${state.currentProject.id}`);
    }
  }

  closeModal() {
    this.setState({
      showModalApprove: false,
      showModalBankId: false,
      isSubmitting: false,
    });
  }

  async updateModalButtonClick(submit: boolean) {
    this.setState({ showModalApprove: false });

    if (submit) {
      await this.submitQualityDocument();
      return;
    }
  }

  render() {
    const fieldLabels: any = {
      'copytoauthorizedcompany': 'Kopia sparad hos behörigt företag',
      'supplementtoclient': 'Kvalitetsdokument lämnad till beställare',
    };

    const {
      showModalApprove,
      showModalBankId,
      fields,
      bankIdMessage,
      isLoading,
      iframeUrl,
      qrImageUpdated,
    } = this.state;

    const modal =
    <IonModal
      isOpen={showModalApprove}
      onDidDismiss={() => this.setState({ showModalApprove: false })}
      cssClass="modal-approve"
      key="modalApprove">
      <IonHeader color="primary">
        <IonToolbar color="primary">
          <IonButtons slot="end">
            <IonButton onClick={() => this.closeModal()}>
              <IonIcon icon={closeCircle} />
            </IonButton>
          </IonButtons>
          <IonTitle>Slutgodkänn kvalitetsdokument</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent className="ion-padding">
        <div>
          <h3>Genom att slutgodkänna blir dessa fält godkända:</h3>
          {fields.map((field: any) => <li key={field}>{fieldLabels[field]}</li>)}
        </div>
      </IonContent>
      <div className="d-flex">
        <IonButton color="danger" onClick={() => this.updateModalButtonClick(false)}>Avbryt</IonButton>
        <IonButton color="success"onClick={() => this.updateModalButtonClick(true)}>Slutgodkänn</IonButton>
      </div>
    </IonModal>;

    const markup = [];

    markup.push(
      <IonButton
      key="buttonUpdateStatus"
      color={this.props.color}
      className="ion-margin-bottom ion-text-wrap"
      expand="block"
      onClick={this.updateStatusButtonClick}
      >
        {this.props.label}
    </IonButton>);

    markup.push(modal);

    markup.push(
      <SignModal
        key="modalBankId"
        closeModal={this.closeModal}
        showModal={showModalBankId}
        message={bankIdMessage}
        qrImage={qrImageUpdated}
        isLoading={isLoading}
        onQRImageTimeout={this.handleQRImageTimeout}
        iframeUrl={iframeUrl} />
    );

    return(
      [markup]
    );
  }
}
export default withRouter(UpdateStatusButton);
